@media only screen and (min-width: 200px) {
  nav {
    background-color: #f8f8f8;
    display: flex;
  }
  .logo {
    display: flex;
    flex-direction: row;
    gap: 5px;
    z-index: 1;
  }
  .rectangles {
    display: flex;
    width: 30px;
    height: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 1;
  }
  .rectangle1 {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    background: #0ff;
  }
  .rectangle2 {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    background: #f0f;
  }
  .rectangle3 {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    background: #ff0;
  }
  .rectangle4 {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    background: #000;
  }
  .rectangle5 {
    width: 100px;
    height: 30px;
    flex-shrink: 0;
    background: white;
    position: absolute;
    /* top: 0px;
    left: 0px; */
    z-index: 0;
  }
  .pageTitle {
    width: 60px;
    height: 30px;
    flex-shrink: 0;
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 15px;
    z-index: 1;
  }
  .ulStyles {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 20px;

    margin: 0;
    padding: 0;
    padding-right: 20px;
  }

  .liStyles {
    /* margin-right: 2px; */
    font-size: 12px;
    font-family: EB Garamond, serif;
  }

  a {
    color: black;
    text-decoration: none;
  }

  a.active {
    font-weight: bold;
  }
}

@media only screen and (min-width: 500px) {
  nav {
    background-color: #f8f8f8;
    display: flex;
  }
  .logo {
    display: flex;
    flex-direction: row;
    gap: 10px;
    z-index: 1;
  }
  .rectangles {
    display: flex;
    width: 40px;
    height: 40px;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 1;
  }
  .rectangle1 {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    background: #0ff;
  }
  .rectangle2 {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    background: #f0f;
  }
  .rectangle3 {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    background: #ff0;
  }
  .rectangle4 {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    background: #000;
  }
  .rectangle5 {
    width: 150px;
    height: 40px;
    flex-shrink: 0;
    background: white;
    position: absolute;
    /* top: 0px;
    left: 0px; */
    z-index: 0;
  }
  .pageTitle {
    width: 50px;
    height: 40px;
    flex-shrink: 0;
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    line-height: 20px;
    z-index: 1;
  }
  .ulStyles {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: right;
    list-style-type: none;
    gap: 30px;
    margin: 0;
    padding: 0;
    padding-right: 20px;
  }

  .liStyles {
    margin-right: 10px;
    font-size: 16px;
    font-family: EB Garamond, serif;
  }

  a {
    color: black;
    text-decoration: none;
  }

  a.active {
    font-weight: bold;
  }
}

@media only screen and (min-width: 1000px) {
  nav {
    background-color: #f8f8f8;
    display: flex;
  }
  .logo {
    display: flex;
    flex-direction: row;
    gap: 10px;
    z-index: 1;
  }
  .rectangles {
    display: flex;
    width: 60px;
    height: 60px;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 1;
  }
  .rectangle1 {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    background: #0ff;
  }
  .rectangle2 {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    background: #f0f;
  }
  .rectangle3 {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    background: #ff0;
  }
  .rectangle4 {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    background: #000;
  }
  .rectangle5 {
    width: 225px;
    height: 60px;
    flex-shrink: 0;
    background: white;
    position: absolute;
    /* top: 0px;
    left: 0px; */
    z-index: 0;
  }
  .pageTitle {
    width: 100px;
    height: 60px;
    flex-shrink: 0;
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 36px;
    font-weight: bold;
    line-height: 30px;
    z-index: 1;
  }
  .ulStyles {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: right;
    list-style-type: none;
    gap: 70px;
    margin: 0;
    padding: 0;
    padding-right: 30px;
  }

  .liStyles {
    margin-right: 10px;
    font-size: 24px;
    font-family: EB Garamond, serif;
  }

  a {
    color: black;
    text-decoration: none;
  }

  a.active {
    font-weight: bold;
  }
}
