.projectList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  justify-content: center; /* centers items horizontally */
  align-items: center;
  margin-top: 50px;
}

@media only screen and (max-width: 1100px) {
  .projectList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    justify-content: center; /* centers items horizontally */
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
