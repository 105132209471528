@media only screen and (min-width: 800px) {
  .about {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    align-items: center;
  }
  .aboutTextOne {
    display: flex;
    flex-direction: row;
    gap: 100px;
  }
  .aboutTextTwo {
    display: flex;
    flex-direction: row;
    gap: 100px;
  }
  .imageContainer {
    margin-top: 50px;
    margin-bottom: 75px;
    width: 100%;
  }
  .spacer {
    width: 100%;
    flex-grow: 1;
    flex-basis: 0%;
  }
  .view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 50px;
  }

  .divider {
    width: 72.7%;
    text-align: right;
    height: 1px;
    background-color: black;
    margin-bottom: 25px;
  }
  .dividerContainer {
    display: flex;
    flex-direction: row-reverse;
  }
  .sectionContainer {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    align-content: center;
  }
  .title {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 30px;
    text-transform: lowercase;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin: 0;
  }
  .titleRest {
    font-weight: 300;
  }
  .pic {
    max-width: 100%;
    height: auto;
  }
  .rectangleContainer {
    display: inline-block;
    position: relative;
    margin-top: 70px;
    margin-bottom: 40px;
    width: 70%;
  }

  .rectangleContainer:after {
    content: "";
    rotate: 4deg;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ebebeb;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .button {
    font-family: Helvetica Neue, Arial, sans-serif;
    letter-spacing: 5px;
    font-weight: bold;
    font-size: 14px;
    padding: 20px;
    width: 200px;
    border-style: solid;
    border-color: black;
    border-width: 2px;
    margin-top: 15px;
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .button1 {
    font-family: Helvetica Neue, Arial, sans-serif;
    letter-spacing: 5px;
    font-weight: bold;
    font-size: 14px;
    padding: 20px;
    width: 200px;
    border-style: solid;
    border-color: black;
    border-width: 2px;
    margin-top: 15px;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .linkContainer {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  .contactTextContainer {
    background-color: #f8f8f8;
    padding: 100px;
    width: 83%;
    display: flex;
    flex-direction: column;
    left: 20%;
    top: 20%;
  }
  .contactText {
    text-align: center;
    color: #000;
    text-align: center;
    font-family: EB Garamond;
    font-size: 36px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 25px;
  }
  .button:hover {
    background-color: #f2f2f2;
    border-style: solid;
    border-color: black;
  }
  .button1:hover {
    background-color: #f2f2f2;
    border-style: solid;
    border-color: black;
  }
  .button1:hover .buttonText1 {
    display: none;
  }
  .button1:hover:before {
    content: "KYUHO@UPENN.EDU";
    color: black;
  }
  .dividerThick {
    width: 100%;
    background-color: #f8f8f8;
    padding: 30px;
    font-size: 24px;
    font-weight: 700;
    color: #525252;
  }
  .moreInfo {
    width: 100%;
    font-size: 25px;
    font-weight: 200;
    display: flex;
    flex-direction: row;
    padding-top: 45px;
    justify-content: space-between;
    gap: 50px;
    padding-bottom: 45px;
  }
  .moreInfoText {
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex-grow: 1;
    width: 50%;
  }
  .link {
    font-weight: 700;
    text-decoration: underline;
  }
  .vertImageSpace {
    flex-grow: 1;
    background-color: #f8f8f8;
    width: 50%;
    overflow: hidden;
    position: relative; /* Add this line */
  }

  .vertImageSpaceImage {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .about {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
  }
  .aboutTextOne {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .aboutTextTwo {
    display: flex;
    flex-direction: column-reverse;
    gap: 25px;
  }
  .imageContainer {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .spacer {
    width: 100%;
    flex-grow: 1;
    flex-basis: 0%;
  }
  .view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 30px;
    width: 90%;
  }
  .title {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 22px;
    text-transform: lowercase;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin: 0;
  }
  .titleRest {
    font-weight: 300;
  }
  p {
    padding: 0;
  }
  .divider {
    width: 72.7%;
    text-align: right;
    height: 1px;
    background-color: black;

    margin-bottom: 25px;
  }
  .dividerContainer {
    display: flex;
    flex-direction: row-reverse;
  }
  .sectionContainer {
    width: 100%;
  }
  .pic {
    max-width: 100%;
    height: auto;
  }
  .rectangleContainer {
    display: inline-block;
    position: relative;
    margin-top: 50px;
    margin-bottom: 40px;
  }

  .rectangleContainer:after {
    content: "";
    rotate: 4deg;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ebebeb;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .button {
    font-family: Helvetica Neue, Arial, sans-serif;
    letter-spacing: 5px;
    font-weight: bold;
    font-size: 10px;
    padding: 20px;
    width: 75px;
    border-style: solid;
    border-color: black;
    border-width: 2px;
    margin-top: 15px;
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .button1 {
    font-family: Helvetica Neue, Arial, sans-serif;
    letter-spacing: 5px;
    font-weight: bold;
    font-size: 10px;
    padding: 20px;
    width: 75px;
    border-style: solid;
    border-color: black;
    border-width: 2px;
    margin-top: 15px;
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .linkContainer {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  .contactTextContainer {
    background-color: #f8f8f8;
    padding: 75px;
    width: 200px;
    display: flex;
    flex-direction: column;
    left: 20%;
    top: 30%;
  }
  .contactText {
    text-align: center;
    color: #000;
    text-align: center;
    font-family: EB Garamond;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px;
  }
  .button:hover {
    background-color: #f2f2f2;
    border-style: solid;
    border-color: black;
  }
  .button1:hover {
    background-color: #f2f2f2;
    border-style: solid;
    border-color: black;
  }
  .button1:hover .buttonText1 {
    display: none;
  }
  .button1:hover:before {
    content: "KYUHO@UPENN.EDU";
    color: black;
  }
  .moreInfo {
    width: 100%;
    font-size: 15px;
    font-weight: 300;
    display: flex;
    flex-direction: column-reverse;
    padding-top: 30px;
    justify-content: space-between;
    gap: 25px;
  }
  .moreInfoText {
    display: flex;
    flex-direction: column;
    gap: 25px;
    flex-grow: 1;
    width: 100%;
  }
  .link {
    font-weight: 700;
    text-decoration: underline;
  }
  .vertImageSpace {
    flex-grow: 1;
    background-color: #f8f8f8;
    width: 100%;
    overflow: hidden;
    position: relative; /* Add this line */
  }

  .vertImageSpaceImage {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .dividerThick {
    width: 100%;
    background-color: #f8f8f8;
    padding: 10px;
    font-size: 15px;
    font-weight: 700;
    color: #525252;
  }
}
@media only screen and (max-width: 400px) {
  .about {
    display: flex;
    width: 100%;

    flex-direction: column;
    margin-top: 0px;
  }

  .view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 30px;
    width: 90%;
  }
  .title {
    font-size: 20px;
  }
}
