.rectangleContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.imagesContainer {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.pic1 {
  position: absolute;
  top: 5%;
  left: 5%;
  width: auto;
  height: 60%;
}
.pic2 {
  position: absolute;
  top: 35%;
  left: 20%;
  width: auto;
  height: 60%;
  z-index: 4;
}
.pic3 {
  position: absolute;
  top: 0%;
  left: 35%;
  width: auto;
  height: 70%;
}
.pic4 {
  position: absolute;
  top: 45%;
  left: 50%;
  width: auto;
  height: 45%;
  z-index: 4;
}
.pic5 {
  position: absolute;
  top: 8%;
  left: 65%;
  width: auto;
  height: 50%;
}

.rect {
  width: 100%;
  height: auto;
  z-index: 0;
}
