@media only screen and (max-width: 500px) {
  .back {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: left;
  }

  .backArrow {
    height: 14px;
  }

  .backText {
    font-family: EB Garamond, serif;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .back:hover {
    color: gray;
  }

  .projectView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px; /* Adjust the width as needed */
    margin: auto;
    margin-top: 50px;
  }

  h2 {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 30px;
    text-transform: lowercase;
    font-weight: 700;
    line-height: 0px;
  }

  .category {
    font-family: Helvetica Neue, Arial, Sans-serif;
    font-size: 10px;
    font-weight: 400;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 10px;
  }
  .project {
    align-items: left;
  }
  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .overview {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: left;
  }
  .problem {
    width: 100%;
    align-items: left;
  }

  h3 {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 15px;
    text-transform: lowercase;
    font-weight: 700;
    line-height: normal;
    text-align: left;

    margin: 0;
  }

  p {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 11px;
    font-weight: 300;
    margin: 0;
    flex-grow: 8;
    flex-basis: 0%;
    padding-top: 4px;
  }
  .title {
    flex-grow: 2;
    flex-basis: 0%;
    width: 100%;
    align-items: start;
    text-align: left;
  }
  .spacer {
    width: 100%;
    flex-grow: 1;
    flex-basis: 0%;
  }
  .divider {
    width: 100%;
    text-align: left;
    height: 0.3px;
    background-color: black;
  }
  .dividerContainer {
    display: flex;
    flex-direction: row-reverse;
  }

  .viewLinkButton {
    width: 100%;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    padding-top: 17px;
    padding-bottom: 17px;
    margin-top: 10px;
  }
  .viewLinkButtonContent {
    display: flex;
    flex-direction: row;
  }
  .viewLinkButtonLink {
    height: 15px;
    margin-right: 10px;
  }
  .viewLinkButtonText {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 12px;
    font-weight: 700;
    color: #808080;
  }
  .viewLinkButton:hover {
    background-color: #ebebeb;
  }
  .about {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 30px;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .info > :first-child {
    display: none;
  }
  .desc {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 10px;
    font-weight: 300;
    margin: 0;
    flex-grow: 8;
    flex-basis: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .desc1 {
    display: flex;
    flex-direction: column;
  }
  .descSub {
    font-weight: 700;
    font-style: italic;
  }
  .descAndBlurb {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  /**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
  .accordion {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .accordionItemHeading {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .accordionTitle {
    padding-left: 20px;
  }
  .accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .accordion__button:hover {
    background-color: #ddd;
  }

  .accordion__button:before {
    display: flex;
    flex-direction: row-reverse;

    content: "";
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 8px solid #444;
    margin-top: 3px;
    margin-right: 20px;
  }

  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    transform: rotate(-90deg);
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    animation: fadein 0.35s ease-in;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 500px) {
  .back {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: left;
  }

  .backArrow {
    height: 30px;
  }

  .backText {
    font-family: EB Garamond, serif;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .back:hover {
    color: gray;
  }

  .projectView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px; /* Adjust the width as needed */
    margin: auto;
    margin-top: 50px;
  }

  h2 {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 36px;
    text-transform: lowercase;
    font-weight: 700;
    line-height: 0px;
  }

  .category {
    font-family: Helvetica Neue, Arial, Sans-serif;
    font-size: 10px;
    font-weight: 400;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 10px;
  }
  .project {
    align-items: left;
  }
  .info {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .overview {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: left;
  }
  .problem {
    width: 100%;
    align-items: left;
  }

  h3 {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 20px;
    text-transform: lowercase;
    font-weight: 700;
    line-height: normal;
    text-align: right;

    margin: 0;
  }

  p {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    flex-grow: 8;
    flex-basis: 0%;
    padding-top: 4px;
  }
  .title {
    flex-grow: 2;
    flex-basis: 0%;
    width: 100%;
    align-items: end;
    text-align: right;
  }
  .spacer {
    width: 100%;
    flex-grow: 1;
    flex-basis: 0%;
  }
  .divider {
    width: 72.7%;
    text-align: right;
    height: 1px;
    background-color: black;
  }
  .dividerContainer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 50px;
  }

  .viewLinkButton {
    width: 100%;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    padding-top: 17px;
    padding-bottom: 17px;
    margin-top: 10px;
  }
  .viewLinkButtonContent {
    display: flex;
    flex-direction: row;
  }
  .viewLinkButtonLink {
    height: 20px;
    margin-right: 10px;
  }
  .viewLinkButtonText {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #808080;
  }
  .viewLinkButton:hover {
    background-color: #ebebeb;
  }
  .about {
    display: flex;
    width: 100%;

    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
  .desc {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    flex-grow: 8;
    flex-basis: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2px;
  }
  .desc1 {
    display: flex;
    flex-direction: column;
  }
  .descSub {
    font-weight: 700;
  }
  .descAndBlurb {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  /**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
  .accordion {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .accordionItemHeading {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .accordionTitle {
    padding-left: 20px;
  }
  .accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .accordion__button:hover {
    background-color: #ddd;
  }

  .accordion__button:before {
    display: flex;
    flex-direction: row-reverse;

    content: "";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 12px solid #444;
    margin-top: 3px;
    margin-right: 20px;
  }

  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    transform: rotate(-90deg);
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    animation: fadein 0.35s ease-in;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 1000px) {
  .back {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: left;
  }

  .backArrow {
    height: 30px;
  }

  .backText {
    font-family: EB Garamond, serif;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .back:hover {
    color: gray;
  }

  .projectView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px; /* Adjust the width as needed */
    margin: auto;
    margin-top: 50px;
  }

  h2 {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 48px;
    text-transform: lowercase;
    font-weight: 700;
    line-height: 0px;
  }

  .category {
    font-family: Helvetica Neue, Arial, Sans-serif;
    font-size: 15px;
    font-weight: 400;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 10px;
  }
  .project {
    align-items: left;
  }
  .info {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .overview {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: left;
  }
  .problem {
    width: 100%;
    align-items: left;
  }

  h3 {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 32px;
    text-transform: lowercase;
    font-weight: 700;
    line-height: normal;
    text-align: right;

    margin: 0;
  }

  p {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 20px;
    font-weight: 300;
    margin: 0;
    flex-grow: 8;
    flex-basis: 0%;
    padding-top: 7px;
  }
  .title {
    flex-grow: 2;
    flex-basis: 0%;
    width: 100%;
    align-items: end;
    text-align: right;
  }
  .spacer {
    width: 100%;
    flex-grow: 1;
    flex-basis: 0%;
  }
  .divider {
    width: 72.7%;
    text-align: right;
    height: 1px;
    background-color: black;
  }
  .dividerContainer {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 50px;
  }

  .viewLinkButton {
    width: 100%;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    padding-top: 17px;
    padding-bottom: 17px;
    margin-top: 20px;
  }
  .viewLinkButtonContent {
    display: flex;
    flex-direction: row;
  }
  .viewLinkButtonLink {
    height: 20px;
    margin-right: 10px;
  }
  .viewLinkButtonText {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #808080;
  }
  .viewLinkButton:hover {
    background-color: #ebebeb;
  }
  .about {
    display: flex;
    width: 100%;

    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
  }
  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
  }
  .desc {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 20px;
    font-weight: 300;
    margin: 0;
    flex-grow: 8;
    flex-basis: 0%;
    padding-top: 9px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .desc1 {
    display: flex;
    flex-direction: column;
  }
  .descSub {
    font-weight: 700;
  }
  .descAndBlurb {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  /**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
  .accordion {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .accordionItemHeading {
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .accordionTitle {
    padding-left: 20px;
  }
  .accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 17px;
    padding-bottom: 17px;
  }

  .accordion__button:hover {
    background-color: #ddd;
  }

  .accordion__button:before {
    display: flex;
    flex-direction: row-reverse;

    content: "";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 12px solid #444;
    margin-top: 3px;
    margin-right: 20px;
  }

  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    transform: rotate(-90deg);
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    animation: fadein 0.35s ease-in;
    margin-bottom: 40px;
  }
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
