@media only screen and (max-width: 500px) {
  .projectContainer {
    background-color: #f8f8f8;
    position: relative;
    height: 225px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(248, 248, 248, 0.9);
  }

  .title {
    color: #000;
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .category {
    color: #000;
    font-family: EB Garamond;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }

  img {
    height: 200px;
    width: auto;
    display: block;
    transition: transform 0.5s;
  }

  a img:hover {
    /* position: relative;
    top: -5px; */
    transform: scale(1.05);
  }
}
@media only screen and (min-width: 500px) {
  .projectContainer {
    background-color: #f8f8f8;
    position: relative;
    height: 425px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 25px;
    padding-top: 15px;
    padding-bottom: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(248, 248, 248, 0.9);
  }

  .title {
    color: #000;
    font-family: Helvetica Neue, Arial, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .category {
    color: #000;
    font-family: EB Garamond;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }

  img {
    height: 400px;
    width: auto;
    display: block;
    transition: transform 0.5s;
  }

  a img:hover {
    /* position: relative;
    top: -5px; */
    transform: scale(1.05);
  }
}
